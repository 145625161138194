import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import {
  CheckboxContainer,
  CheckboxLabel,
  CloseButton,
  ContentContainer,
  DocumentsModalFormContainer,
  DownContainer,
  DownloadButton,
  ErrorParagraph,
  InputForm,
  LeftContainer,
  RightFormContainer,
  SmallTextContainer,
  TextContainer,
  UpContainer
} from './styled.components';
import {
  H2Style,
  H3Style,
  ParagraphMediumStyle
} from '../../../views/documents-page/styled.components';
import { StaticImage } from 'gatsby-plugin-image';
import SelectForm from '../../../views/registration-form/components/form-section/components/select-form';
import { getDocumentsValidationSchema } from './documents-validation-schema';
import { useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans } from 'react-i18next';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const DocumentsModal = ({
  isModalOpen,
  setIsModalOpen,
  pdfDocument,
  multipleDocuments
}) => {
  const { t } = useTranslation();

  const closeModal = () => {
    reset();
    setDownloadError(false);
    setIsModalOpen(false);
  };

  const { schema } = getDocumentsValidationSchema(t);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      organizationType: '',
      organizationName: '',
      position: '',
      policy: false
    }
  });

  const { errors } = methods.formState;
  const { register, setValue, watch, handleSubmit, reset } = methods;

  const [boxStyles, setBoxStyles] = useState({
    overlay: {
      zIndex: 103
    },
    content: {
      margin: 'auto',
      padding: '0',
      inset: '0',
      width: '80%',
      maxHeight: '80vh',
      maxWidth: '850px',
      borderRadius: '8px',
      background: '#FFF'
    }
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 700px)');

    const updateBoxStyles = (e) => {
      if (e.matches) {
        setBoxStyles((prevStyles) => ({
          ...prevStyles,
          content: {
            ...prevStyles.content,
            width: '100%'
          }
        }));
      } else {
        setBoxStyles((prevStyles) => ({
          ...prevStyles,
          content: {
            ...prevStyles.content,
            width: '80%'
          }
        }));
      }
    };

    updateBoxStyles(mediaQuery);

    mediaQuery.addEventListener('change', updateBoxStyles);

    return () => {
      mediaQuery.removeEventListener('change', updateBoxStyles);
    };
  }, []);

  const OrganizationTypeOptions = [
    {
      label: 'Startup',
      value: 'Startup'
    },
    {
      label: t('documents-modal.organizationType-option-investment-fund'),
      value: 'Fundusz inwestycyjny'
    },
    {
      label: t('documents-modal.organizationType-option-public-institution'),
      value: 'Instytucja publiczna'
    },
    {
      label: t('documents-modal.organizationType-option-business-support'),
      value: 'Organizacja wsparcia biznesu'
    },
    {
      label: t('documents-modal.organizationType-option-company'),
      value: 'Przedsiębiorstwo'
    }
  ];

  const handleOrganizationTypeChange = (value) => {
    setValue('organizationType', value.value, {
      shouldValidate: false
    });
  };

  const handlePolicyAcceptedChange = (e, policyName) => {
    const isChecked = e.target.checked;
    setValue(policyName, isChecked);
  };

  const [downloadError, setDownloadError] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const onSubmit = async (data) => {
    setDownloadError(false);
    setDownloading(true);
    var downloadedFile = '';

    if (multipleDocuments === true) {
      downloadedFile = pdfDocument.map((doc) => doc.node.title).join(', ');
    } else {
      downloadedFile = pdfDocument.node.title;
    }

    const values = {
      created: new Date().toUTCString(),
      name: data.name,
      email: data.email,
      phone: data.phone,
      organizationType: data.organizationType,
      organizationName: data.organizationName,
      position: data.position,
      downloadedFile: downloadedFile,
      policy: data.policy
    };

    axios
      .post('https://www.innovationshub.pl/api/documents-form', values)
      .then(async function (response) {
        console.log(response);
        if (multipleDocuments === true) {
          await downloadFilesAsZip(pdfDocument);
        } else {
          await downloadFile(pdfDocument);
        }
        setDownloading(false);
      })
      .catch(function (error) {
        console.log(error);
        setDownloadError(true);
        setDownloading(false);
      });
  };

  const downloadFile = async (downloadedFile) => {
    try {
      const response = await fetch(downloadedFile.node.file.file.url);
      const blob = await response.blob();

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = downloadedFile.node.title.replace(' ', '_');
      link.style.display = 'none';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);

      closeModal();
    } catch (error) {
      setDownloadError(true);
      console.error('Error downloading the file:', error);
    }
  };

  const downloadFilesAsZip = async (downloadedFiles) => {
    if (!Array.isArray(downloadedFiles) || downloadedFiles.length === 0) {
      console.error('No files to download');
      return;
    }

    const zip = new JSZip();

    try {
      for (const file of downloadedFiles) {
        const response = await fetch(file.node.file.file.url);
        const blob = await response.blob();
        const fileName = file.node.title.replace(/\s+/g, '_') + '.pdf';
        zip.file(fileName, blob);
      }

      const zipBlob = await zip.generateAsync({ type: 'blob' });

      saveAs(zipBlob, 'downloaded_files.zip');

      closeModal();
    } catch (error) {
      setDownloadError(true);
      console.error('Error downloading files:', error);
    }
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        style={boxStyles}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}>
        <DocumentsModalFormContainer onSubmit={handleSubmit(onSubmit)}>
          <UpContainer>
            <svg
              onClick={closeModal}
              className="close-image"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="#5FA5C5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <ContentContainer>
              <LeftContainer>
                <TextContainer>
                  <H2Style color="#0F5575">{t`documents-modal.title`}</H2Style>
                  <ParagraphMediumStyle>{t`documents-modal.description`}</ParagraphMediumStyle>
                </TextContainer>
                <StaticImage
                  className="iot-image"
                  src="../../../images/documents-page/modal/documents-form.webp"
                  alt="IoT image"
                  placeholder="none"
                  loading="lazy"
                />
              </LeftContainer>
              <RightFormContainer>
                <SmallTextContainer>
                  <H3Style color="#0F5575">{t`documents-modal.title`}</H3Style>
                  <ParagraphMediumStyle>{t`documents-modal.description`}</ParagraphMediumStyle>
                </SmallTextContainer>
                <InputForm>
                  <label>
                    {t`documents-modal.label-name`} <span>*</span>
                  </label>
                  <input
                    {...register('name')}
                    placeholder={t`documents-modal.input-placeholder-name`}
                  />
                  {errors.name && (
                    <ErrorParagraph>{errors.name.message}</ErrorParagraph>
                  )}
                </InputForm>
                <InputForm>
                  <label>
                    {t`documents-modal.label-email`} <span>*</span>
                  </label>
                  <input
                    {...register('email')}
                    placeholder={t`documents-modal.input-placeholder-email`}
                  />
                  {errors.email && (
                    <ErrorParagraph>{errors.email.message}</ErrorParagraph>
                  )}
                </InputForm>
                <InputForm>
                  <label>
                    {t`documents-modal.label-phone`} <span>*</span>
                  </label>
                  <input
                    {...register('phone')}
                    placeholder={t`documents-modal.input-placeholder-phone`}
                  />
                  {errors.phone && (
                    <ErrorParagraph>{errors.phone.message}</ErrorParagraph>
                  )}
                </InputForm>
                <InputForm>
                  <label>
                    {t`documents-modal.label-organizationType`} <span>*</span>
                  </label>
                  <SelectForm
                    options={OrganizationTypeOptions}
                    value={{ ...watch('organizationType') }}
                    placeholder={t`documents-modal.input-placeholder-organizationType`}
                    onChange={handleOrganizationTypeChange}
                  />
                  {errors.organizationType && (
                    <ErrorParagraph>
                      {errors.organizationType.message}
                    </ErrorParagraph>
                  )}
                </InputForm>
                <InputForm>
                  <label>
                    {t`documents-modal.label-organizationName`} <span>*</span>
                  </label>
                  <input
                    {...register('organizationName')}
                    placeholder={t`documents-modal.input-placeholder-organizationName`}
                  />
                  {errors.organizationName && (
                    <ErrorParagraph>
                      {errors.organizationName.message}
                    </ErrorParagraph>
                  )}
                </InputForm>
                <InputForm>
                  <label>
                    {t`documents-modal.label-position`} <span>*</span>
                  </label>
                  <input
                    {...register('position')}
                    placeholder={t`documents-modal.input-placeholder-position`}
                  />
                  {errors.position && (
                    <ErrorParagraph>{errors.position.message}</ErrorParagraph>
                  )}
                </InputForm>
                <CheckboxContainer>
                  <CheckboxLabel>
                    <input
                      type="checkbox"
                      {...register('policy')}
                      onChange={(e) => handlePolicyAcceptedChange(e, 'policy')}
                    />
                    <span className="checkmark"></span>
                    <Trans
                      i18nKey="documents-modal.paragraph-policy"
                      components={{
                        b: <b />,
                        span: <span className="error" />
                      }}
                    />
                  </CheckboxLabel>
                  {errors.policy && (
                    <ErrorParagraph>{errors.policy.message}</ErrorParagraph>
                  )}
                </CheckboxContainer>
              </RightFormContainer>
            </ContentContainer>
            {downloadError && (
              <ErrorParagraph>{t`documents-modal.download-error`}</ErrorParagraph>
            )}
          </UpContainer>
          <DownContainer>
            <CloseButton
              onClick={closeModal}
              type="button">{t`documents-modal.close-button`}</CloseButton>
            {!downloading && (
              <DownloadButton type="submit">{t`documents-modal.download-button`}</DownloadButton>
            )}
            {downloading && (
              <DownloadButton
                type="button"
                className="downloading">{t`documents-modal.downloading-button`}</DownloadButton>
            )}
          </DownContainer>
        </DocumentsModalFormContainer>
      </Modal>
    </>
  );
};

export default DocumentsModal;
