import styled from 'styled-components';

export const PuzzleCanvasContainer = styled.div`
  background: var(--UI-Blue-Tint, #f5fafd);

  .container {
    display: flex;
    padding: 60px 0;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
  }
`;

export const UpCanvasContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
`;

export const UpCanvasTitleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  @media only screen and (max-width: 1200px) {
    margin: auto;
  }
`;

export const UpCanvasH3 = styled.h3`
  color: var(--UI-Dark-Blue, #0f5575);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
`;

export const SpcContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 100px;
  align-self: stretch;

  .puzzle-canvas-image {
    max-width: 500px;
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    gap: 24px;

    .puzzle-canvas-image {
      margin: auto;
    }
  }
`;

export const SpcRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  flex: 1 0 0;
`;

export const SpcDescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const SpcWhySection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;

export const BottomCanvasContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const BottomCanvasTitleSection = styled.div`
  display: flex;
  padding: 40px 0px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  align-self: stretch;
`;

export const H3Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const SwitchPuzzleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export const SwitchingButtonsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  overflow: hidden;
  border: 2px solid var(--UI-Grey-blue, #c4dfec);
`;

export const SwitchButton = styled.button`
  display: flex;
  padding: 8px 24px;
  align-items: flex-start;
  gap: 10px;
  height: 55px;
  box-sizing: border-box;
  border: 0px;
  background: var(--White, rgba(255, 255, 255, 0.9));

  cursor: pointer;
  transition: all 0.3s ease-in-out;

  color: var(--UI-Blue, #5fa5c5);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.36px;
  align-items: center;

  &.checked,
  &:hover {
    background: var(--UI-Blue, #5fa5c5);
    color: #fff;
  }
`;

export const SwitchingDataContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;

  .right-image {
    flex: 1 0 0;
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: column;

    .right-image {
      flex: auto;
    }
  }
`;

export const SwitchingDataLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 50%;

  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`;

export const ToDownloadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  background: #fff;
`;

export const UpToDownload = styled.div`
  display: flex;
  height: 69px;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  box-sizing: border-box;
  cursor: pointer;

  &.disable-clicking {
    cursor: auto;
  }

  svg {
    padding: 8px;
    transition: transform 0.3s ease-in-out;
  }
  .rotate-svg {
    transform: rotate(180deg);
    transform-origin: center;
  }
  .tooltip {
    margin-left: auto;
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background: #e5f2fa;
    color: #080e14;
    font-family: Raleway;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    padding: 8px 4px;
    border-radius: 8px;

    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
  .download-svg {
    margin-left: auto;
    cursor: pointer;
  }
`;

export const BottomToDownload = styled.div`
  display: flex;
  padding: 0px 16px 16px 16px;
  align-items: stretch;
  align-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
`;

export const DownloadTile = styled.div`
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #e5f2fa;
  align-items: center;
  box-sizing: border-box;
  width: 270px;
  height: inherit;
  svg,
  p {
    margin-bottom: auto;
  }

  .download-svg {
    margin-left: auto;
    cursor: pointer;
    flex-shrink: 0;
  }
`;

export const PdfTile = styled.div`
  display: flex;
  padding: 4px 16px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: #fff;
  margin-bottom: auto;
`;
